import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
// import { InvitationStatusEnum } from '@/core/enums/invitation-status.enum';
import { SETTINGS_STORE } from '@/store/modules/settings';
import { USER_CONTRACTOR_STORE } from '@/store/modules/user-contractor';
export default defineComponent({
    props: {
        contractor: {
            type: Object,
            required: true
        },
        hasActions: {
            type: Boolean,
            default: false
        }
    },
    created() {
    },
    computed: {
        canManage() {
            const { contractor } = this;
            return contractor?.toUser?.userVerificationStatus === UserVerificationStatusEnum.VERIFIED;
        },
        status() {
            const { contractor } = this;
            let status = 'PENDING INVITATION';
            if (contractor?.toUser) {
                status = 'PENDING VERIFICATION';
            }
            if (contractor?.toUser?.userVerificationStatus === UserVerificationStatusEnum.VERIFIED) {
                status = 'IKNOWA VERIFIED';
            }
            return status;
        }
    },
    methods: {
        ...mapActions(SETTINGS_STORE, ['setRemoveTradeContractorModal', 'setManageContractorsModal']),
        ...mapActions(USER_CONTRACTOR_STORE, ['setToRemoveContractorId', 'setToManageContractor']),
        onRemove(id) {
            this.setRemoveTradeContractorModal(true);
            this.setToRemoveContractorId(id);
        },
        onManage(contractor) {
            this.setManageContractorsModal(true);
            this.setToManageContractor(contractor);
        },
    },
});
