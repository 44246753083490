<template>
    <section class="main-form-container">
        <el-scrollbar>
           <slot name="form-content"></slot>
        </el-scrollbar>
        <!-- <div class="action-container">
          <el-button
              type="primary"
              class="yellow-button"
              round
              :loading="submitting"
              >SAVE
          </el-button>
        </div> -->
    </section>
</template>
<script>
export default {
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.main-form-container {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  height:100%;
  margin-right: 30px;

  .el-scrollbar {
    padding-right: 10px;
    :deep(.el-scrollbar__bar.is-horizontal) {
      display: none;
    }
    :deep(.el-scrollbar__bar.is-vertical) {
      width: 6px;
    }
  }

  .action-container {
    display:flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 70px;

    .yellow-button {
        background: #FAA100;
        border-radius: 7px;
        color: #fff;
        outline: none;
        border: none;
        width: 150px;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-align: center;
    }
  }
}

@include media-md-max-width() {
  .main-form-container {
    margin-right: 0;
    height: 97%;

    .action-container {
      width: 100%;

      .yellow-button {
        width: 100%;
      }
    }
  }
}
@include media-sm-max-width() {
  .main-form-container {
    height: 89%;
  }
}
</style>
