<template>
    <div class="profile-list-item-container flex-row ai-c jc-sb">
        <div class="profile-list-item-container__wrapper flex-row ai-c">
          <el-checkbox v-model="selected" @change="onCheck" size="large">
            <div class="profile-list-item-container__wrapper flex-row ai-c">
              <UserThumbnail
                borderRadius="100%"
                width="30px"
                height="30px"
                :key="require(`@/assets/images/box-empty.jpeg`)"
                :source="require(`@/assets/images/box-empty.jpeg`)"
                >
              </UserThumbnail>
              <p class="profile-list-item-container__text default-text">
                {{profileName}}
              </p>
            </div>
          </el-checkbox>
        </div>
        <div class="profile-list-item-container__wrapper flex-row ai-c" v-if="hasActions">
          <p class="profile-list-item-container__action-text default-text cursor-pointer" @click="viewProfile">View <span class="hide-in-mobile">profile</span></p>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import { USER_CONTRACTOR_STORE } from '@/store/modules/user-contractor';

export default defineComponent({
  components: {
    UserThumbnail
  },
  props: {
    profileName: {
      type: String,
      default: 'n/a'
    },
    userId: {
      type: Number,
    },
    hasActions: {
      type: Boolean,
      default: true
    },
    isChecked: {
      type: Boolean
    }
  },
  data() {
    return {
      selected: false
    };
  },

  watch: {
    'toManageContractor.isPreference': {
      immediate: true,
      deep: true,
      handler(value) {
        this.selected = value;
      }
    }
  },

  computed: {
    ...mapGetters(USER_CONTRACTOR_STORE, ['toManageContractor']),
  },

  methods: {
    ...mapActions(USER_CONTRACTOR_STORE, ['setToManageContractor']),

    viewProfile() {
      this.$router.push(`/profile/${this.userId}/about`);

      // should we open it in a new tab?
      // const host = `${window.location.protocol}//${window.location.host}/profile/${this.toManageContractor?.toUser.id}/about`;
      // window.open(host, '_blank');
    },

    onCheck() {
      const { selected, toManageContractor } = this;

      this.setToManageContractor({
        ...toManageContractor,
        isPreference: selected
      });
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.profile-list-item-container {
    padding: 0 0 .8rem 0;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(12, 15, 74, 0.2);

    &:last-child {
      border-bottom: none;
    }
    &__text {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.25px;
    }
    &__wrapper {
        gap:.5rem;
    }
    &__action-text {
      font-size: 14px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0.25px;
      color: #FFA500;
    }
}
.el-checkbox {
    height: auto;
}

@include media-xs-max-width() {
  .profile-list-item-container {
    &__text {
      font-size: 14px;
    }
  }
}
</style>
