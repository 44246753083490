import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import SendInviteFormComponent from '@/core/components/common/forms/SendInviteFormComponent.vue';
import EmailListItem from '@/core/components/common/list/EmailListItem.vue';
import EmptyStateText from '@/core/components/ui/EmptyStateText.vue';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
import UserContractorService from '@/core/services/user-contractor.service';
import SettingSlotContainer from '@/modules/account-settings/components/SettingSlotContainer.vue';
import ManageContractorModal from '@/modules/account-settings/modals/ManageContractorModal.vue';
import RemoveTradeContractorConfirmationModal from '@/modules/account-settings/modals/RemoveTradeContractorConfirmationModal.vue';
import { SETTINGS_STORE } from '@/store/modules/settings';
import { USER_CONTRACTOR_STORE } from '@/store/modules/user-contractor';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        SettingSlotContainer,
        SendInviteFormComponent,
        EmailListItem,
        EmptyStateText,
        RemoveTradeContractorConfirmationModal,
        ManageContractorModal
    },
    data() {
        return {
            contractors: [],
        };
    },
    watch: {
        userContractors: {
            immediate: true,
            deep: true,
            handler(list) {
                this.contractors = list;
            }
        }
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(SETTINGS_STORE, ['getRemoveTradeContractorModal', 'getManageContractorsModal']),
        ...mapGetters(USER_CONTRACTOR_STORE, ['userContractors', 'toRemoveContractorId', 'toManageContractor']),
    },
    // beforeUnmount() {
    //   this.setUserContractors([]);
    // },
    created() {
        this.initialize();
    },
    methods: {
        ...mapActions(SETTINGS_STORE, ['setRemoveTradeContractorModal', 'setManageContractorsModal']),
        ...mapActions(USER_CONTRACTOR_STORE, ['getUserContractors', 'deleteUserContractor']),
        async initialize() {
            const { id: userId } = this.user;
            await this.getUserContractors(userId)
                .catch(() => {
                this.$notify.error({
                    title: 'Error Getting Contractor List',
                    message: GENERIC_ERROR_MESSAGE,
                });
            });
        },
        onSuccessfulInviteCallback() {
            this.initialize();
        },
        sendInvite() {
            //   function to send invite;
        },
        onRemove() {
            const { toRemoveContractorId } = this;
            this.deleteUserContractor({
                userId: this.user.id,
                userContractorId: toRemoveContractorId
            }).then(() => {
                this.setRemoveTradeContractorModal(false);
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error Deleting',
                    message: GENERIC_ERROR_MESSAGE,
                });
            });
        },
        async onManageSave() {
            const { toManageContractor } = this;
            const { toUser } = toManageContractor;
            await UserContractorService.updateUserContractor(toUser.id, toManageContractor.id, {
                invitationStatus: toManageContractor.invitationStatus,
                isPreference: toManageContractor.isPreference
            }).finally(() => {
                this.setManageContractorsModal(false);
            });
        }
    },
});
