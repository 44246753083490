import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import StringHelper from '@/core/helpers/string.helper';
import { USER_CONTRACTOR_STORE } from '@/store/modules/user-contractor';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    emits: ['on-successful-callback'],
    data() {
        return {
            submitting: false,
            validEmail: false,
            form: {
                email: ''
            },
            rules: {
                email: [
                    { required: true, message: 'Email is required', trigger: 'blur' },
                ],
            },
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
    },
    methods: {
        ...mapActions(USER_CONTRACTOR_STORE, ['createUserContractor']),
        onSendInvite() {
            const { $refs, $notify, submitting } = this;
            if (submitting) {
                return;
            }
            $refs.inviteForm.validate((valid) => {
                let isValid = valid;
                if (!StringHelper.isValidEmail(this.form.email)) {
                    this.$notify.error({
                        message: 'Please enter a valid email!'
                    });
                    isValid = false;
                }
                if (isValid) {
                    this.submitting = true;
                    this.createUserContractor({
                        userId: this.user.id,
                        userContractor: this.form
                    })
                        .then(() => {
                        this.$emit('on-successful-callback');
                    })
                        .catch((e) => {
                        if (e?.data?.status === 500) {
                            $notify.error({
                                title: 'System Error',
                                message: 'Cannot invite the provided email, please try again.'
                            });
                        }
                        if (e?.data?.status === 400) {
                            $notify.info({
                                title: '',
                                message: e?.data?.message
                            });
                        }
                    })
                        .finally(() => {
                        this.submitting = false;
                    });
                }
            });
        },
        onChangeEmail() {
            this.validEmail = false;
        },
    },
});
