<template>
    <div class="empty-state-wrapper ai-c jc-c">
      <p class="default-text">{{message}}</p>
    </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Nothing here'
    }
  }
};
</script>
<style lang="scss" scoped>
.empty-state-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    p {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: rgba(12, 15, 74, 0.5);
        text-align: center;
    }
}
</style>
