<template>
  <nav class="menu-container">
      <el-menu :default-active="menuName" :router="false" ref="accountSettingSidebar" class="el-menu-vertical menu-container" background-color="transparent" text-color="rgba(12, 15, 74, 0.5)">
          <el-menu-item class="menu-item" v-for="(menu, i) in menus" :key="i" @click="onSelectMenu(menu)" :index="menu.name">
            <template v-if="allowedMenus.includes(menu.name)">
              <div class="details-container">
                  <span>
                    <img :src="menu.icon" v-if="selectedMenu !== menu.screenType">
                    <img :src="menu.iconActive" v-if="selectedMenu === menu.screenType">
                  </span>
                  <div class="details">
                      <p class="label">{{menu.name}}</p>
                      <p class="description">{{menu.description}}</p>
                  </div>
              </div>
              <el-icon :size="20" color="rgba(12, 15, 74, 0.5)"><ArrowRightBold/></el-icon>
            </template>
          </el-menu-item>
      </el-menu>
  </nav>
</template>
<script>
import {
  ArrowRightBold,
} from '@element-plus/icons';
import { computed, ref } from 'vue';
import { mapGetters } from 'vuex';

import {
  ACCOUNT_DETAILS_NAME,
  ACCOUNT_DETAILS_SCREEN, SECURITY_NAME, SECURITY_SCREEN, SUBSCRIPTION_NAME, SUBSCRIPTION_SCREEN,
  TRADE_CONTRACTORS_NAME, TRADE_CONTRACTORS_SCREEN
} from '@/core/components/settings/constants';

export default {
  components: {
    ArrowRightBold,
  },

  data() {
    return {
      ACCOUNT_DETAILS_SCREEN,
      SECURITY_SCREEN,
      SUBSCRIPTION_SCREEN,
      TRADE_CONTRACTORS_SCREEN,
      ACCOUNT_DETAILS_NAME,
      SECURITY_NAME,
      SUBSCRIPTION_NAME,
      TRADE_CONTRACTORS_NAME,

      allowedMenus: [
        ACCOUNT_DETAILS_SCREEN,
        SECURITY_SCREEN,
        SUBSCRIPTION_SCREEN,
        TRADE_CONTRACTORS_SCREEN,
        ACCOUNT_DETAILS_NAME,
        SECURITY_NAME,
        SUBSCRIPTION_NAME,
      ]
    };
  },

  computed: {
    ...mapGetters(['isTradesperson', 'isProjectOwner']),
  },

  created() {
    if (this.isProjectOwner) {
      this.allowedMenus.push(TRADE_CONTRACTORS_NAME);
    }
  },

  //   initial data and algo for menu
  props: ['selected', 'menuName'],
  setup(props, { emit }) {
    const menus = ref([
      /* eslint-disable global-require */
      {
        iconActive: `${require('@/assets/icons/account-icon.svg')}`, icon: `${require('@/assets/icons/account-details-dark-icon.svg')}`, screenType: ACCOUNT_DETAILS_SCREEN, name: ACCOUNT_DETAILS_NAME, description: 'Personal information'
      },
      {
        iconActive: `${require('@/assets/icons/security-light-icon.svg')}`, icon: `${require('@/assets/icons/security-icon.svg')}`, screenType: SECURITY_SCREEN, name: SECURITY_NAME, description: 'Change password'
      },
      {
        iconActive: `${require('@/assets/icons/subscription-light-icon.svg')}`, icon: `${require('@/assets/icons/subscription-icon.svg')}`, screenType: SUBSCRIPTION_SCREEN, name: SUBSCRIPTION_NAME, description: 'Manage subscriptions & billing'
      },
      {
        disableForTP: true, iconActive: `${require('@/assets/icons/sole-trader-icon-light.svg')}`, icon: `${require('@/assets/icons/tp-icon.svg')}`, screenType: TRADE_CONTRACTORS_SCREEN, name: TRADE_CONTRACTORS_NAME, description: 'Manage your preferred contractors'
      },
      // {
      //   iconActive: `${require('@/assets/icons/account-icon.svg')}`, icon: `${require('@/assets/icons/notifications-icon.svg')}`, name: 'Notifications', description: 'Type noti that you want to receiven'
      // },
      // {
      //   iconActive: `${require('@/assets/icons/account-icon.svg')}`, icon: `${require('@/assets/icons/billing-icon.svg')}`, name: 'Billing', description: 'Edit card or billing address'
      // },
      /* eslint-enable global-require */
      //   { icon: require('@/assets/icons/security-icon.svg'), name: 'Security' },
      //   { icon: require('@/assets/icons/billing-icon.svg'), name: 'Billing' },
    ]);

    function onSelectMenu(menu) {
      emit('select-menu', menu);
    }

    const selectedMenu = computed(() => {
      return props.selected;
    });

    return {
      menus,
      onSelectMenu,
      selectedMenu
    };
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.menu-container {
    position:relative;
    // padding:3rem .5rem 0 1rem;
    // padding-top: 1rem;
    width: 100%;

    .el-menu {
        // margin-top: 2rem;
        display:flex;
        flex-direction: column;
        // gap: 2rem;
    }

    .el-menu-item {
        width: 100%;
        display:flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.5rem;
        border-bottom: 2px solid rgba(12, 15, 74, 0.1);
        padding: 1rem 0 !important;
        box-sizing: unset;

        .details-container {
            display:flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1.5rem;
            span {
                background-color: rgba(12, 15, 74, 0.05);
                border-radius: 8px;
                width: 50px;
                height: 50px;
                display:flex;
                justify-content: center;
                align-items: center;
            }

            .details {
                display:flex;
                flex-direction: column;
                // align-items:center;
                // gap:.5rem;
                justify-content: center;
                .label {
                    font-family: Mulish;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 25px;
                    letter-spacing: 0.25px;
                    margin: 0;
                    color: #0C0F4A;
                }
                .description {
                    font-family: Mulish;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: 0.25px;
                    margin:0;
                    color:rgba(12, 15, 74, 0.5);
                }
            }
        }
    }

    .el-menu-item:last-child {
        border-bottom: none;
    }
    .el-menu-item.is-active {
        span {
          background-color: #FAA100;
        }
    }
    .el-menu-item:hover {
       background: transparent;
       span {
          background-color: #FAA100;
          transition: .3s ease-in-out;
        }
    }
}

@include media-xs-max-width() {
  .menu-container {
    .el-menu-item {
      .details-container {
        .details {
          .label {
            font-size: 16px;
          }
          .description {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
