<template>
    <SettingSlotContainer>
        <template #form-content>
           <section class="main-subscription">
              <div class="main-subscription__container flex-column" v-if="isTradesperson">
                <div class="main-subscription__content-wrapper flex-column">
                    <p class="main-subscription__header default-text">Current subscriptions</p>
                    <!-- <CollapsibleBox v-if="isProjectOwner">
                      <template #header>
                          <DefaultDetailsHeaderComponent title="Properties" count="1" :icon="propertyIcon"/>
                      </template>
                      <template #content>
                          <PropertySubscriptionDetails/>
                      </template>
                    </CollapsibleBox>
                    <CollapsibleBox v-if="isProjectOwner">
                      <template #header>
                          <DefaultDetailsHeaderComponent title="Workstations" count="2" :icon="workstationIcon"/>
                      </template>
                      <template #content>
                          <WorkstationSubscriptionDetails/>
                      </template>
                    </CollapsibleBox> -->
                    <!-- <CollapsibleBox v-if="isTradesperson">
                      <template #header>
                          <DefaultDetailsHeaderComponent title="Membership" titleSpan="(Sole trader)" :icon="memberIcon"/>
                      </template>
                      <template #content>
                          <MembershipSubscriptionDetails @has-finished-action="hasFinishedAction"/>
                      </template>
                    </CollapsibleBox> -->
                    <!-- <CollapsibleBox>
                      <template #header>
                          <DefaultDetailsHeaderComponent title="Storage" titleSpan="(Private)" :icon="storageIcon"/>
                      </template>
                      <template #content>
                          <StorageSubscriptionDetails/>
                      </template>
                    </CollapsibleBox> -->
                </div>
                <el-divider/>
                <!-- <div class="main-subscription__content-wrapper flex-column">
                    <p class="main-subscription__header default-text">Payment method</p>
                    <CollapsibleBox :isCollapsible="false">
                      <template #content>
                          <PaymentMethodDetails @has-finished-action="hasFinishedAction"/>
                      </template>
                    </CollapsibleBox>
                </div>
                <div class="main-subscription__content-wrapper flex-column">
                    <p class="main-subscription__header default-text">Billing address</p>
                    <CollapsibleBox :isCollapsible="false">
                      <template #content>
                          <BillingAddressDetails/>
                      </template>
                    </CollapsibleBox>
                </div> -->
            </div>
            <EmptyStateText message="You don’t currently have any subscriptions." v-else/>
         </section>
       </template>
    </SettingSlotContainer>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

// import DefaultDetailsHeaderComponent from '@/core/components/common/header/DefaultDetailsHeaderComponent.vue';
// import BillingAddressDetails from '@/core/components/settings/BillingAddressDetails.vue';
// import MembershipSubscriptionDetails from '@/core/components/settings/MembershipSubscriptionDetails.vue';
// import PaymentMethodDetails from '@/core/components/settings/PaymentMethodDetails.vue';
// import PropertySubscriptionDetails from '@/core/components/settings/PropertySubscriptionDetails.vue';
// import StorageSubscriptionDetails from '@/core/components/settings/StorageSubscriptionDetails.vue';
// import WorkstationSubscriptionDetails from '@/core/components/settings/WorkstationSubscriptionDetails.vue';
// import CollapsibleBox from '@/core/components/ui/CollapsibleBox.vue';
import EmptyStateText from '@/core/components/ui/EmptyStateText.vue';
import SettingSlotContainer from '@/modules/account-settings/components/SettingSlotContainer.vue';
import { USERS_STORE } from '@/store/modules/users';

export default {
  components: {
    SettingSlotContainer,
    // CollapsibleBox,
    // DefaultDetailsHeaderComponent,
    // PropertySubscriptionDetails,
    // StorageSubscriptionDetails,
    // WorkstationSubscriptionDetails,
    // PaymentMethodDetails,
    // BillingAddressDetails,
    // MembershipSubscriptionDetails,
    EmptyStateText
  },
  data() {
    return {
      /* eslint-disable global-require */
      propertyIcon: `${require('@/assets/icons/home-filled-icon.svg')}`,
      workstationIcon: `${require('@/assets/icons/workspace-logo-two.svg')}`,
      storageIcon: `${require('@/assets/icons/storage-icon.svg')}`,
      memberIcon: `${require('@/assets/icons/tp-icon.svg')}`,
      /* eslint-disable global-require */
      hasSubscriptions: true
    };
  },
  computed: {
    ...mapGetters(['isTradesperson', 'isProjectOwner']),
  },

  methods: {
    ...mapActions(USERS_STORE, ['initializeUserState']),

    async hasFinishedAction() {
      await this.initializeUserState();
    }
  }
};
</script>
<style lang="scss" scoped>
.main-subscription {
    height: 100%;
    position: relative;
    &__container {
      padding: 0 1rem;
    }

    &__header {
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.25px;
    }
}
</style>
