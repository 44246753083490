import { Check } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import StringHelper from '@/core/helpers/string.helper';
import { USERS_STORE } from '../../../../store/modules/users';
import SettingSlotContainer from '../SettingSlotContainer.vue';
export default defineComponent({
    components: {
        Check,
        SettingSlotContainer,
    },
    data() {
        return {
            rules: {
                firstName: [
                    { required: true, trigger: 'change', message: 'First name is required' }
                ],
                lastName: [
                    { required: true, trigger: 'change', message: 'Last name is required' },
                ],
                contactNo: [
                    { required: true, trigger: 'change', message: 'Phone number is required' },
                ]
            },
            isManualForm: false,
            userForm: {},
            defaultEmail: '',
            submittingForm: false
        };
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        ...mapGetters(USERS_STORE, ['user'])
    },
    created() {
        this.initialize();
    },
    methods: {
        ...mapActions(USERS_STORE, [
            'updateUser',
            'initializeUserState'
        ]),
        initialize() {
            const { user } = this;
            this.userForm = {
                firstName: user.firstName,
                lastName: user.lastName,
                contactNo: user.contactNo,
                address: user.address,
                postcode: user.postcode
            };
            this.defaultEmail = StringHelper.removeEmailAlias(user.email);
        },
        onSubmitUpdateDetails() {
            this.updateUser({ user: this.userForm, userId: this.user.id })
                .then(async (response) => {
                if (response) {
                    this.$notify.success({
                        title: 'Success',
                        message: 'You have successfully updated your details.'
                    });
                    // update the user state in store to keep the user info updated across the page
                    await this.initializeUserState();
                }
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'There\'s a problem updating your details at this moment. Please try again.'
                });
            })
                .finally(() => {
                this.submittingForm = false;
            });
        },
        changeFormState() {
            this.isManualForm = !this.isManualForm;
        }
    }
});
