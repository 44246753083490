<template>
    <section class="main-container">
        <!-- <div class="header-container">
           <p class="header">Password</p>
           <el-icon @click="openChangePassForm" :size="15" color="#0C0F4A" v-if="openForm"><CloseBold/></el-icon>
           <el-button
              v-else
              type="primary"
              class="yellow-button"
              round
              :loading="submitting"
              @click="openChangePassForm"
              >CHANGE PASSWORD
          </el-button>
        </div> -->
        <el-form
            :model="changePassword"
            ref="changePasswordForm"
            class="form"
            :rules="rules"
            size="small"
            status-icon
            :validate-on-rule-change="true"
            :hide-required-asterisk="true"
            label-position="top"
          >
            <!-- <el-form-item
                label="Current password"
                prop="currentPassword"
                class="iknowa-form-item"
                >
                <el-input
                    class="iknowa-input-default"
                    type="text"
                    :maxlength="255"
                    tabindex="1"
                ></el-input>
            </el-form-item> -->
            <el-form-item
              label="New password"
              prop="newPassword"
              class="iknowa-form-item"
            >
              <el-input
                class="iknowa-input-default"
                type="password"
                :maxlength="255"
                tabindex="1"
                v-model="changePassword.newPassword"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="Re-type new password"
              prop="confirmPassword"
              class="iknowa-form-item"
            >
              <el-input
                class="iknowa-input-default"
                type="password"
                :maxlength="255"
                tabindex="2"
                v-model="changePassword.confirmPassword"
              ></el-input>
            </el-form-item>
            <el-button
                type="primary"
                class="button active"
                round
                :loading="submitting"
                @click="onSubmitChangePassword"
                >CHANGE
            </el-button>
          </el-form>
    </section>
</template>
<script>
// import { CloseBold } from '@element-plus/icons';
import * as CryptoJS from 'crypto-js';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import StringHelper from '@/core/helpers/string.helper';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';

import { USERS_STORE } from '../../../../store/modules/users';

export default defineComponent({
  components: {
    // CloseBold,
  },

  data() {
    const passwordValidator = (rule, value, cb) => {
      if (!value || value.length === 0) {
        return cb(new Error('Password is required.'));
      }

      // eslint-disable-next-line no-useless-escape
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\=\+\-\^\$\*\.\[\]\{\}\(\)\?\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`])(?!.*\s).{8,}$/.test(value)) {
        return cb(new Error('Requires a minimum of 8 total characters, with 1 upper case, 1 lower case and 1 symbol'));
      }
      return cb();
    };
    const checkConfirmPassword = (rule, value, cb) => {
      if (!value || value.length > 0) {
        if (value !== this.changePassword.newPassword) {
          return cb(new Error('Password does not match.'));
        }
        return cb();
      }
      return cb(new Error('Confirm password is required.'));
    };

    return {
      rules: {
        newPassword: [
          { required: true, validator: passwordValidator, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, validator: checkConfirmPassword, trigger: 'blur' },
        ]
      },
      changePassword: {
        newPassword: '',
        confirmPassword: '',
      },
      valid: false,
      submitting: false,

      openForm: false
    };
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user'])
  },

  beforeUnmount() {
    this.setForgotPasswordTempUser({
      userId: 0,
      email: ''
    });
  },

  methods: {
    ...mapActions(AUTHENTICATION_STORE, ['setForgotPasswordTempUser']),

    initialize() {
      const { user } = this;

      if (user) {
        this.setForgotPasswordTempUser({
          userId: user.id,
          email: StringHelper.removeEmailAlias(user.email)
        });
      }
    },

    onSubmitChangePassword() {
      // eslint-disable-next-line consistent-return
      this.$refs.changePasswordForm.validate((valid) => {
        if (valid) {
          const password = CryptoJS.AES.encrypt(this.changePassword.newPassword, process.env.VUE_APP_CRYPTO_SECRET_KEY).toString();
          this.submitting = true;

          this.$store.dispatch(`${AUTHENTICATION_STORE}/changePassword`, { password })
            .then(() => {
              this.$notify.success({
                message: 'You successfully changed your password.'
              });
            })
            .catch((e) => {
              this.$notify.error({
                title: 'Error',
                message: e?.data?.message || 'Error changing your password.'
              });
            })
            .finally(() => {
              this.submitting = false;
            });
        } else {
          this.$notify.error({
            title: 'Error',
            message: 'Please provide a valid matching passwords.'
          });
        }
      });
    },
  }
});
</script>
<style lang="scss" scoped>
.main-container {
    display:flex;
    flex-direction: column;
    gap: 2.5rem;

    .header-container * p {
        margin: 0;
    }
    .header-container {
        display:flex;
        justify-content: space-between;
        align-items: center;

        .el-icon {
          cursor: pointer;
        }
    }
    .el-form {
        :deep(.el-form-item__label) {
            font-family: Mulish;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: left;
            color: #0C0F4A;
        }

        :deep(.el-input__inner) {
            background: rgba(12, 15, 74, 0.05);
            border: none;
            color: #0C0F4A;
            border-radius: 100px;
            font-size: 14px;
            padding: 20px;
            font-family: Arial;
            border-color: #fa4b00;
            height: 42px;
            font-family: Mulish;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: left;
        }
    }
}
.button {
    background: rgba(12, 15, 74, 0.1);
    border-radius: 7px;
    color: rgba(12, 15, 74, 0.5);
    outline: none;
    border: none;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
    height: 42px;
    box-sizing: border-box;
    padding: 0 2rem;
}
.button.active {
  background: #FAA100;
  color: #fff;
}
</style>
