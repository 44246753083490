<template>
    <section class="settings-page-container" v-if="hasMainLayoutInitialized">
        <div class="main-menu-container" :class="[viewProfileSettings ? 'close' : 'open']">
           <AccountSettingsMenuComponent :selected="selectedMenu" :menuName="menuName" @select-menu="toggleProfileSettings($event)"/>
        </div>
        <div class="settings-content flex-column" :class="[viewProfileSettings ? 'open' : 'close']">
           <div class="flex-row ai-c show-in-mobile" style="cursor: pointer; gap: .7rem;" @click="toggleProfileSettings">
              <el-icon :size="20"><ArrowLeftBold/></el-icon>
              <p class="default-text">Back</p>
           </div>
           <AccountDetailsComponent v-if="selectedMenu === ACCOUNT_DETAILS_SCREEN"/>
           <SecurityComponent v-if="selectedMenu === SECURITY_SCREEN"/>
           <SubscriptionComponent v-if="selectedMenu === SUBSCRIPTION_SCREEN"/>
           <TradeContractorsComponent v-if="selectedMenu === TRADE_CONTRACTORS_SCREEN"/>
           <!-- <NotificationsComponent v-if="selectedMenu === 'Notifications'"/>
           <BillingComponent v-if="selectedMenu === 'Billing'"/> -->
        </div>
    </section>
</template>
<script>
import { ArrowLeftBold } from '@element-plus/icons';
import { ref } from 'vue';
import { mapGetters } from 'vuex';

import {
  ACCOUNT_DETAILS_NAME,
  ACCOUNT_DETAILS_SCREEN, SECURITY_SCREEN, SUBSCRIPTION_NAME, SUBSCRIPTION_SCREEN, TRADE_CONTRACTORS_SCREEN
} from '@/core/components/settings/constants';
import { USERS_STORE } from '@/store/modules/users';

import AccountSettingsMenuComponent from '../components/AccountSettingsMenuComponent.vue';
import AccountDetailsComponent from '../components/form/AccountDetailsComponent.vue';
// import NotificationsComponent from '../components/form/NotificationsComponent.vue';
import SecurityComponent from '../components/form/SecurityComponent.vue';
// import BillingComponent from '../components/BillingComponent.vue';
import SubscriptionComponent from '../components/SubscriptionComponent.vue';
import TradeContractorsComponent from '../components/TradeContractorsComponent.vue';

export default {
  components: {
    AccountDetailsComponent,
    AccountSettingsMenuComponent,
    // BillingComponent,
    // NotificationsComponent,
    SecurityComponent,
    ArrowLeftBold,
    SubscriptionComponent,
    TradeContractorsComponent
  },
  data() {
    return {
      ACCOUNT_DETAILS_SCREEN,
      SECURITY_SCREEN,
      SUBSCRIPTION_SCREEN,
      ACCOUNT_DETAILS_NAME,
      SUBSCRIPTION_NAME,
      TRADE_CONTRACTORS_SCREEN
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized', 'user']),
  },

  setup() {
    const selectedMenu = ref(ACCOUNT_DETAILS_SCREEN);
    const menuName = ref(ACCOUNT_DETAILS_NAME);
    const viewProfileSettings = ref(false);

    const toggleProfileSettings = (event) => {
      if (!event) {
        viewProfileSettings.value = !viewProfileSettings.value;
      }
      selectedMenu.value = event.screenType;
      menuName.value = event.name;
      viewProfileSettings.value = !viewProfileSettings.value;
    };

    return {
      selectedMenu,
      viewProfileSettings,
      toggleProfileSettings,
      menuName
    };
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.settings-page-container {
  width: 100%;
  margin-left: 10px;
  display:flex;
  gap: 5rem;
  max-width:1030px;
  height: calc(100% - 70px);
  justify-content: flex-start;
  position: relative;

  .settings-content {
     width: 100%;
  }
}

@include media-md-max-width() {
  .settings-page-container {
    width: auto;
    height: 100%;
    margin: 0 15px;
    gap: 2.5rem;

    .main-menu-container {
      width: 100%;
    }
  }
}

@include media-sm-max-width() {
  .settings-page-container {
    .close {
      display: none;
    }
    .open {
      display: flex;
    }
  }
}
</style>
