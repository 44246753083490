<template>
    <SettingSlotContainer>
       <template #form-content>
           <section class="main-security-container">
               <ChangePasswordFormComponent/>
               <!-- <TwoStepVerificationFormComponent/>
               <IdCheckComponent/> -->
           </section>
       </template>
    </SettingSlotContainer>
</template>
<script>
import SettingSlotContainer from '../SettingSlotContainer.vue';
// import IdCheckComponent from '../IdCheckComponent.vue';
import ChangePasswordFormComponent from './ChangePasswordFormComponent.vue';
// import TwoStepVerificationFormComponent from './TwoStepVerificationFormComponent.vue';

export default {
  components: {
    ChangePasswordFormComponent,
    SettingSlotContainer,
    // IdCheckComponent,
    // TwoStepVerificationFormComponent,
  }
};
</script>
<style lang="scss" scoped>
.main-security-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
</style>
