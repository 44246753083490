<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  @cancel="cancel"
  :hasActions="false"
>
  <div class="modal-content flex-column jc-sb">
    <div class="flex-column jc-sb">
        <div class="modal-content__modal-header flex-row jc-sb ai-fs">
            <div class="modal-content__header-wrapper flex-row ai-c" style="gap: .5rem;">
              <h2 class="modal-content__header-title default-text">Manage contractor</h2>
            </div>
            <div class="modal-content__header-icon cursor-pointer">
              <inline-svg @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
            </div>
        </div>
        <div class="modal-content__content-wrapper flex-column">
            <EmailListItem :contractor="toManageContractor" :hasActions="false"/>
            <div class="modal-content__list-container flex-column">
                <el-scrollbar>
                    <div class="modal-content__scrollbar-content flex-column">
                       <div class="modal-content__item flex-column">
                           <p class="modal-content__item-title default-text">Sole trader</p>
                           <ProfileListItemCheckbox v-if="userId" :isChecked="isChecked" :userId="userId" :profileName="profileName"/>
                       </div>
                       <!-- Once workstation is available, we use them as reference -->
                       <!-- <div class="modal-content__item flex-column">
                           <p class="modal-content__item-title default-text">Limited company</p>
                           <ProfileListItemCheckbox profileName="Recom Construction Ltd"/>
                           <ProfileListItemCheckbox profileName="Sweet Homes Ltd"/>
                       </div>
                       <div class="modal-content__item flex-column">
                           <p class="modal-content__item-title default-text">Team</p>
                            <ProfileListItemCheckbox profileName="Skreenhouse"/>
                            <ProfileListItemCheckbox profileName="Superstars"/>
                       </div> -->
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <div class="modal-content__actions flex-row ai-c">
            <el-button :disabled="loading" :loading="loading" class="modal-content__button default-text flex-row ai-c jc-c" @click="confirm">Save</el-button>
        </div>
    </div>
  </div>
</BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

import EmailListItem from '@/core/components/common/list/EmailListItem.vue';
import ProfileListItemCheckbox from '@/core/components/common/list/ProfileListItemCheckbox.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { USER_CONTRACTOR_STORE } from '@/store/modules/user-contractor';
// import UserContractor from '@/store/modules/user-contractor/interface';

export default defineComponent({
  name: 'current-members-plan-modal',
  emits: ['on-cancel', 'on-confirm'],
  components: {
    BaseModal,
    EmailListItem,
    ProfileListItemCheckbox
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(USER_CONTRACTOR_STORE, ['toManageContractor']),

    profileName() {
      return `${this.toManageContractor?.toUser?.firstName} ${this.toManageContractor?.toUser?.lastName}`;
    },

    isChecked() {
      return this.toManageContractor?.isPreference;
    }
  },

  methods: {
    ...mapActions(USER_CONTRACTOR_STORE, ['createUserContractor']),

    userId() {
      return this.toManageContractor?.toUser?.id;
    },

    cancel() {
      this.$emit('on-cancel');
    },

    confirm() {
      this.$emit('on-confirm');
    },
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.modal-content {
  min-width: 400px;
    &__content {
        padding-right: 1rem;
        border-right: 2px solid rgba(12, 15, 74, 0.1);
    }
    &__header-title {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.25px;
    }
    &__header-icon {
        cursor: pointer;
    }
    &__wrapper {
    }
    &__item-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.25px;
    }
    &__button {
        height: 42px;
        width: 100%;
        border-radius: 8px;
        background: #FFA500;
        font-size: 15px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-align: center;
        color: #FFF;
        border: none;
        text-transform: uppercase;

        &:hover {
            background: #FFA500;
            color: #FFF;
        }
    }
}
@include media-xs-max-width() {
  .modal-content {
    min-width: 300px;
    &__content {
        padding-right: 0;
        border-right: none;
    }
  }
}
</style>
